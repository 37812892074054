<template>
  <div class="usermessage-container">
    <div class="tabbar-container">
      <van-icon class="popup-back" @click="Back" name="arrow-left" />
      信息完善
    </div>

    <div class="message-container">
      <van-cell-group inset>
        <van-field
          v-model="companyadress"
          label="所属辖区"
          readonly
          @click="findXQ"
          placeholder="请选择辖区"
          input-align="right"
        />
        <van-field
          v-model="classname"
          label="练习班级"
          readonly
          @click="findBJ"
          placeholder="请选择班级"
          input-align="right"
        />
        <van-field
          v-model="studentname"
          label="名字"
          placeholder="请输入名字"
          input-align="right"
        />
        <van-field
          v-model="phonenumber"
          label="手机号"
          placeholder="请输入手机号"
          input-align="right"
        />
        <van-field
          v-model="idCard"
          label="身份证号"
          placeholder="请输入身份证号"
          input-align="right"
        />
        <van-field
          v-model="company"
          label="单位名称"
          placeholder="请输入单位名称"
          input-align="right"
        />
        <div class="checkout" @click="saveMessage">保存</div>
      </van-cell-group>
    </div>

    <van-popup v-model="showXq" round position="bottom">
      <van-picker
        title="所属辖区"
        show-toolbar
        :columns="xqData"
        @confirm="confirmXq"
        @cancel="onCancel"
      />
    </van-popup>

    <van-popup v-model="showBj" round position="bottom">
      <van-picker
        title="练习班级"
        show-toolbar
        :columns="bjData"
        @confirm="confirmBj"
        @cancel="onCancel"
      />
    </van-popup>

    <van-overlay
      :show="addLoading"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <van-loading
        style="
          position: absolute;
          top: calc(50% - 25px);
          left: calc(50% - 30px);
        "
        size="50px"
        color="#0094ff"
        vertical
        text-color="#0094ff"
        >加载中</van-loading
      >
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "user",
  components: {},
  computed: {},
  data() {
    return {
      addLoading: false,
      showXq: false,
      showBj: false,
      xqData: [],
      bjData: [],
      bjDataAll: [],

      xxws: require("../../assets/icon/icon_xxws.png"),
      companyadress: "",
      classname: "",
      classid: "",
      studentname: "",
      phonenumber: "",
      idCard: "",
      company: "",

      sex: "",
      education: "",
      photourl: "",
      createDate: "",
      score: "",
      szhuangtai: "",
      xzqu: "",
      hylbid: "",
      hylb: "",
      xzquid: "",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.loadData();
  },
  destroyed() {},
  methods: {
    async confirmXq(row) {
      if (this.companyadress != row) {
        await this.loadBJ(row);
      }

      this.companyadress = row;
      this.classname = "";
      this.showXq = false;
    },
    confirmBj(row, index) {
      this.classname = row;
      this.classid = this.bjDataAll[index].classid;
      this.createDate = this.bjDataAll[index].createDate;
      this.hylb = this.bjDataAll[index].hylb;
      this.hylbid = this.bjDataAll[index].hylbid;
      this.xzqu = this.bjDataAll[index].xzqu;
      this.xzquid = this.bjDataAll[index].xzquid;

      this.showBj = false;
    },
    onCancel() {
      this.showXq = false;
      this.showBj = false;
    },
    findXQ() {
      this.showXq = true;
    },
    findBJ() {
      if (this.companyadress) {
        this.showBj = true;
      } else {
        this.$toast("请选择辖区");
      }
    },
    Back() {
      this.$emit("fatherMethod");
    },
    async loadData() {
      this.addLoading = true;
      setTimeout(() => {
        this.addLoading = false;
      }, 10000);
      const student = await this.$store.dispatch("api/getUserInfoForId", {
        studentid: localStorage.getItem("openid"),
      });

      if (student.success) {
        this.companyadress = student.result.companyadress;
        this.classname = student.result.classname;
        this.classid = student.result.classid;
        this.studentname = student.result.studentname;
        this.phonenumber = student.result.phonenumber;
        this.idCard = student.result.idcard;
        this.company = student.result.company;

        this.sex = student.result.sex;
        this.education = student.result.education;
        this.photourl = student.result.photourl;
        this.createDate = student.result.createDate;
        this.score = student.result.score;
        this.szhuangtai = student.result.szhuangtai;
        this.xzqu = student.result.xzqu;
        this.hylbid = student.result.hylbid;
        this.hylb = student.result.hylb;
        this.xzquid = student.result.xzquid;

        const xqResult = await this.$store.dispatch("api/getSysDictionary", {
          bdname: "XZQu",
        });

        if (xqResult.success) {
          xqResult.result.forEach((xq) => {
            this.xqData.push(xq.dname);
          });
        }

        if (this.companyadress) {
          await this.loadBJ(this.companyadress);
        }

        this.addLoading = false;
      } else {
        this.addLoading = false;
      }
    },
    // 获取班级
    async loadBJ(row) {
      const bjResult = await this.$store.dispatch("api/getTrain", {
        xzqu: row,
      });

      if (bjResult.success) {
        this.bjData = [];
        this.bjDataAll = bjResult.result;
        bjResult.result.forEach((bj) => {
          this.bjData.push(bj.classname);
        });
      }
    },
    saveMessage() {
      if (!this.companyadress) {
        this.$toast("请选择所属辖区");
        return;
      }
      if (!this.studentname) {
        this.$toast("请填写姓名");
        return;
      }
      if (!this.phonenumber) {
        this.$toast("请填写手机号");
        return;
      }
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phonenumber)) {
        this.$notify({ type: "warning", message: "手机号格式错误" });
        return;
      }
      if (!this.idCard) {
        this.$toast("请填写身份证号");
        return;
      }
      // 身份证验证。
      if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.idCard)) {
        this.$toast("身份证格式错误");
        return false;
      }
      if (!this.company) {
        this.$toast("请填写单位名称");
        return;
      }

      this.addLoading = true;
      setTimeout(() => {
        this.addLoading = false;
      }, 10000);

      const form = {
        studentid: localStorage.getItem("openid"),
        studentname: this.studentname,
        phonenumber: this.phonenumber,
        company: this.company,
        idcard: this.idCard,
        companyadress: this.companyadress,
        classid: this.classid,
        classname: this.classname,

        sex: this.sex,
        education: this.education,
        photourl: this.photourl,
        createDate: this.createDate,
        score: this.score,
        szhuangtai: this.szhuangtai,
        xzqu: this.xzqu,
        hylbid: this.hylbid,
        hylb: this.hylb,
        xzquid: this.xzquid,
      };

      this.$store
        .dispatch("api/trainstudents", form)
        .then((response) => {
          if (response.success) {
            this.addLoading = false;
            this.$toast("信息完善成功");
            this.Back();
          } else {
            this.addLoading = false;
            this.$toast(response.message);
          }
        })
        .catch((error) => {
          this.addLoading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.usermessage-container {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#2d8efc, #1ccaa3);
  overflow-x: hidden;
}
.tabbar-container {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 1rem;
  position: relative;
  font-size: 1.1rem;

  .popup-back {
    position: absolute;
    left: 20px;
    top: 5px;
  }
}

.message-container {
  background: #ffffff;
  padding-top: 1rem;
  border-radius: 10px;
  width: 90%;
  margin-left: 5%;
  margin-top: 3.5rem;
}

/deep/ .van-cell-group--inset {
  margin: 0;
}

/deep/ .van-cell {
  width: 100% !important;
  margin: 0 !important;
}

.checkout {
  width: 90%;
  height: 44px;
  margin-left: 5%;
  border-radius: 999px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: row;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(270deg, #2d8efc, #1ccaa3);
  color: #ffffff;
  font-size: 1rem;
}
</style>
