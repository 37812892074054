<template>
  <div class="my-container">
    <div class="tabbar-container">我的</div>
    <div class="user-container">
      <van-image
        v-show="userView"
        width="5rem"
        height="5rem"
        fit="fill"
        round
        :src="headimgurl"
      />
      <div v-show="userView" class="user-message">
        <div style="font-size: 1.2rem">{{ nickname }}</div>
      </div>

      <div v-show="!userView" class="checkin" @click="userLogin">微信登录</div>
    </div>

    <van-cell v-show="userView" is-link @click="userShow" center>
      <!-- 使用 title 插槽来自定义标题 -->
      <template #title>
        <div class="xxws-container">
          <van-image width="1rem" height="1rem" fit="fill" :src="xxws" />
          <span class="custom-title">信息完善</span>
        </div>
      </template>
    </van-cell>

    <div v-show="userView" class="checkout" @click="userBack">退出登录</div>

    <van-popup
      v-model="showUserMessage"
      position="right"
      :lock-scroll="false"
      :style="{ width: '100%', height: '100%' }"
    >
      <user v-if="showUserMessage" @fatherMethod="Back" />
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import user from './userMessage.vue'
export default {
  name: 'my',
  components: { user },
  computed: {},
  data() {
    return {
      xxws: require('../../assets/icon/icon_xxws.png'),
      showUserMessage: false,

      userView: false,
      headimgurl: '',
      nickname: '',
    }
  },
  watch: {},
  created() {},
  mounted() {
    if (localStorage.getItem('openid')) {
      this.userView = true
      this.headimgurl = localStorage.getItem('headimgurl')
      this.nickname = localStorage.getItem('nickname')
    }
  },
  destroyed() {},
  methods: {
    userShow() {
      this.showUserMessage = true
    },
    Back() {
      this.showUserMessage = false
    },
    userLogin() {
      //   window.open(
      //     'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1b5377d2b79cdedd&redirect_uri=https%3A%2F%2F` + this.$store.getters.appconfig.wxConfig.urlVerify +`&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect',
      //     '_self'
      //   );
      window.location.href =
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=` +
        this.$store.getters.appconfig.wxConfig.appid +
        `&redirect_uri=https%3A%2F%2F` +
        this.$store.getters.appconfig.wxConfig.urlVerify +
        `%3Fversion%3D${new Date().getTime()}&response_type=code&scope=snsapi_userinfo&state=${new Date().getTime()}#wechat_redirect`
    },
    userBack() {
      Dialog.confirm({
        title: '确认退出登录',
      })
        .then(() => {
          localStorage.setItem('openid', '')
          localStorage.setItem('headimgurl', '')
          localStorage.setItem('nickname', '')
          localStorage.setItem('code', '')
          window.location.href =
            `https://` +
            this.$store.getters.appconfig.wxConfig.webUrl +
            `?version=${new Date().getTime()}`
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.my-container {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#2d8efc, #1ccaa3);
  overflow-x: hidden;
}

.tabbar-container {
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
}

.user-container {
  width: 100%;
  height: 9rem;
  margin-top: 15px;
  background: rgba($color: #ffffff, $alpha: 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;

  .user-message {
    margin-left: 20px;
    color: #ffffff;
  }
}
.custom-title {
  margin-left: 15px;
}
.xxws-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/deep/ .van-cell {
  width: 90%;
  margin-left: 5%;
  border-radius: 10px;
  margin-top: 2rem;
}

.checkout {
  width: 90%;
  height: 44px;
  margin-left: 5%;
  border-radius: 10px;
  margin-top: 1rem;
  display: flex;
  align-items: row;
  align-items: center;
  justify-content: center;
  background: #d25252;
  color: #ffffff;
  font-size: 1rem;
}

.checkin {
  width: 90%;
  height: 44px;
  margin-left: 5%;
  border-radius: 10px;
  margin-top: 1rem;
  display: flex;
  align-items: row;
  align-items: center;
  justify-content: center;
  background: #01c65a;
  color: #ffffff;
  font-size: 1rem;
}
</style>
