<template>
  <div class="wh100">
    <van-cell-group class="cellGroup">
      <van-cell value="切换帐号"
                class="horCenter" />
    </van-cell-group>
    <van-cell-group class="cellGroup">
      <van-cell value="退出登录"
                class="horCenter"
                @click="loginout" />
    </van-cell-group>
  </div>
</template>

<script> 
export default {
  name: 'setting',
  components: {},
  data () {
    return {
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
    loginout () {
      this.$dialog.confirm({
        title: '系统提示',
        message: '尊敬的用户您好,即将退出系统, 是否继续?',
      })
        .then(() => {
          this.$store.dispatch('user/loginout').then(() => {
            location.reload()
          })
        })
        .catch(() => { });
    }
  }
}
</script>

<style lang="scss">
.userInfo {
  display: flex;
  align-items: center;
}
.imgUser {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
</style>
