<template>
  <div class="wh100">
    <van-cell-group>
      <van-cell is-link
                center>
        <template #title>
          <div class="userInfo">
            <img class="imgUser"
                 :src="imgUser">
            <div>
              <div style="font-weight:bold;font-size:1.1rem">昵称：{{userInfo.fullName}}</div>
              <div>帐号：{{userInfo.userName}}</div>
            </div>
          </div>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group class="cellGroup">
      <van-cell title="信息完善"
                icon="orders-o" />
      <van-cell title="人脸录入"
                icon="aim"
                is-link />
    </van-cell-group>

    <van-cell-group class="cellGroup">
      <van-cell title="设置"
                icon="setting-o"
                is-link
                @click="jumpPage" />
    </van-cell-group>
  </div>
</template>

<script> 
export default {
  name: 'personal',
  components: {},
  data () {
    return {
      imgUser: require('@/assets/logo.png'),
      userInfo: this.$store.getters.user
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
    jumpPage () {
      this.$jumpPage('/system/setting', { title: "设置", type: "1" })
    }
  }
}
</script>

<style lang="scss">
.userInfo {
  display: flex;
  align-items: center;
}
.imgUser {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
</style>
