<template>
  <div class="errPage-container"
       style="background: #edeef4;height: 100%;">
    <van-row>
      <van-col :span="12">
        <h2>您访问页面无效</h2>
        <h6>您可以执行以下操作</h6>
        <ul class="list-unstyled">
          <li class="link-type">
            <router-link to="/">
              自动进入系统
            </router-link>
          </li>
        </ul>
      </van-col>
      <van-col :span="24">
        <img :src="errGif"
             width="313"
             height="428"
             alt="图片加载失败">
      </van-col>
    </van-row>
  </div>
</template>

<script>
import errGif from '@/assets/404_images/404.gif'

export default {
  name: 'Page404',
  data () {
    return {
      errGif: errGif + '?' + +new Date(),
      dialogVisible: false
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.errPage-container {
  overflow: hidden;
  .pan-back-btn {
    background: #008489;
    color: #fff;
    border: none !important;
  }
  .pan-gif {
    margin: 0 auto;
    display: block;
  }
  .pan-img {
    display: block;
    margin: 0 auto;
  }
  .text-jumbo {
    font-size: 35px;
    font-weight: 700;
    color: #484848;
  }
  .list-unstyled {
    font-size: 14px;
    a {
      color: #008489;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
